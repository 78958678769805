
  import Vue, {PropType} from "vue";
  import {PostType} from "@/services/blog";
  import {ROUTES} from "@/router/routes";

  export default Vue.extend({
    name: "pwa-blog-post-card",
    props: {
      blogPost: {
        type: Object as PropType<PostType>,
      },
    },
    data() {
      return {
        routes: ROUTES,
      }
    },
  });
